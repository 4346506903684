window.addEventListener("DOMContentLoaded", function (e) {

    const toggleButtonNodeList = document.querySelectorAll(".ticket-passes-comparison-list__item__sellingpoints-toggle-button-wrapper button");

    if (toggleButtonNodeList.length === 0)
        return;


    toggleButtonNodeList.forEach(function(button) {

        button.addEventListener("click", function () {

            const expandText = button.dataset.expandtext;
            const collapseText = button.dataset.collapsetext;
            const toggleElement = button.parentElement.previousElementSibling;
            const spanElement = button.querySelector("span");

            if (button.classList.contains("active")) {
                button.classList.remove("active");
                toggleElement.hidden = true;
                spanElement.textContent = expandText;
            } else {
                button.classList.add("active");
                toggleElement.hidden = false;
                spanElement.textContent = collapseText;
            }

            try {
                $.fn.matchHeight._update();
            } catch (error) {
                console.error(error);
            }
        });

    });

});